import { useState, useEffect, useContext } from 'react';
import * as React from 'react';
import {
  withAuthenticationRequired,
  useAuth0,
} from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import Loading from '../components/Loading';
import AppContext from '../context';
import Logout from './Logout';

function ProtectedRoute({ component, ...props }) {
  const { auth0Tok } = useContext(AppContext);
  const { isLoading } = useAuth0();
  const [isValidToken, setValidToken] = useState(null);

  useEffect(() => {
    // when auth0Tok is refreshed
    function checkValidity() {
      if (auth0Tok) {
        const { exp } = jwtDecode(auth0Tok);
        setValidToken(exp * 1000 < (new Date()).getTime());
      }
    }
    checkValidity();
  }, [auth0Tok]);

  const Component = withAuthenticationRequired(component, {
    // eslint-disable-next-line react/no-unstable-nested-components
    onRedirecting: () => <Loading />,
  });

  // Handle loading and invalid token cases
  if (isLoading) {
    return <Loading />;
  }

  if (isValidToken && !isLoading) {
    return <Logout />;
  }

  return <Component body={props.body} />;
}

export default ProtectedRoute;

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  body: PropTypes.element.isRequired,
};
