import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';

import { Card } from '@mui/material';
import ClientCheckBox from '../clientFields/ClientCheckbox';
import ClientTextField from '../clientFields/ClientTextField';
import ClientDropDown from '../clientFields/ClientDropDown';

function OtherInfoTab({
  setFunction, data, permissions, structure,
}) {
  const setter = setFunction;
  const [liveData, setLiveData] = useState({ ...data });
  const [userPermissions, setUserPermissions] = useState({ ...permissions });
  const [dataStructure, setDataStructure] = useState({ ...structure });

  useEffect(() => {
    setLiveData(data);
    setUserPermissions(permissions);
    setDataStructure(structure);
  }, [data]);

  return (
    <div id="other-info" className="client-tab">
      <Card
        id="curate-card"
        className="client-card"
        variant="outlined"
        sx={{ p: 3 }}
      >
        <h2>Curate</h2>
        <ClientCheckBox
          setFunction={setter}
          data={liveData.curate_subscriber}
          attribute="curate_subscriber"
          label="Curate Subscriber"
          permissions={userPermissions}
          structure={dataStructure}
        />
        <ClientCheckBox
          setFunction={setter}
          data={liveData.curate_delivery_auto_upload}
          attribute="curate_delivery_auto_upload"
          label="Auto Upload"
          permissions={userPermissions}
          structure={dataStructure}
        />
        <ClientDropDown
          setFunction={setter}
          data={liveData.curate_frequency}
          attribute="curate_frequency"
          label="Frequency"
          permissions={userPermissions}
          structure={dataStructure}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.curate_days}
          attribute="curate_days"
          label="Curate Days"
          permissions={userPermissions}
          structure={dataStructure}
        />
      </Card>
      <ClientTextField
        setFunction={setter}
        data={liveData.annelewis_slack_channel}
        attribute="annelewis_slack_channel"
        label="Slack Channel"
        permissions={userPermissions}
        structure={dataStructure}
      />
      <ClientDropDown
        setFunction={setter}
        data={liveData.timezone}
        attribute="timezone"
        label="Timezone"
        permissions={userPermissions}
        structure={dataStructure}
      />
      <ClientDropDown
        setFunction={setter}
        data={liveData.fiscal_year_start}
        attribute="fiscal_year_start"
        label="Fiscal Year Start"
        permissions={userPermissions}
        structure={dataStructure}
      />
    </div>
  );
}

export default OtherInfoTab;

OtherInfoTab.propTypes = {
  permissions: PropTypes.oneOfType([PropTypes.object]),
  structure: PropTypes.oneOfType([PropTypes.object]),
  setFunction: PropTypes.func,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
